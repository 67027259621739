import { lazy, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { AuthContext } from '../components/Auth/AuthProvider'
import CaiPopup from '../components/CaiPopup'
import { CaiChatProvider } from '../context/CaiChatContext'
import CategoryList from './Category/CategoryList'
import { Roles } from '../constants/common.constant'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { MobileOnboarding } from 'src/components/CaiPopup/MobileOnboarding'

const Dashboard = lazy(() => import('./Dashboard'))
const ITDashboard = lazy(() => import('./IT/ITDashboard'))
const ProgramBuilder = lazy(() => import('./ProgramBuilder'))
const Programs = lazy(() => import('./Programs'))
const Tasks = lazy(() => import('./Tasks'))
const ProgressTracker = lazy(() => import('./ProgressTracker'))
const Presentation = lazy(() => import('./App/Presentation'))
const Help = lazy(() => import('./Dashboard/Help'))
const EmployeeProfile = lazy(() => import('./Employee/EmployeeProfile'))
const EmployeeTask = lazy(() => import('./Employee/EmployeeTask'))
const Library = lazy(() => import('./Library'))
const Teams = lazy(() => import('./Teams'))
const Header = lazy(() => import('../components/common/Header'))
const TaskEdit = lazy(() => import('./TaskEdit'))
const EmployeeDashboard = lazy(() => import('./Employee/EmployeeDashboard'))
const PersonalHub = lazy(() => import('./PersonalHub'))
const PersonalSettings = lazy(() => import('./PersonalSettings'))
const Admin = lazy(() => import('./Admin'))
const StarterProgramList = lazy(
  () => import('./StarterProgram/StarterProgramList'),
)
const OrganizationList = lazy(() => import('./Organization/OrganizationList'))

const GeneralRoutes = ({ roleName }) => {
  const routes = [
    { path: '/task/:taskId', component: Presentation },
    { path: '/task/:taskId/:taskInstanceId', component: Presentation },
    { path: '/task-duplicate/:taskId', component: TaskEdit },
    { path: '/task-edit/:taskId', component: TaskEdit },
    { path: '/help', component: Help },
  ]

  return (
    <>
      <Route exact path="/">
        <Redirect
          to={`${process.env.PUBLIC_URL}/${roleName.toLowerCase()}-dashboard`}
        />
      </Route>

      {routes.map((r, i) => (
        <Route
          key={i}
          exact
          component={r.component}
          path={`${process.env.PUBLIC_URL}${r.path}`}
        />
      ))}
    </>
  )
}

const HrRoutes = () => {
  const routes = [
    { path: '/hr-dashboard', component: Dashboard },
    { path: '/hr-programs', component: Programs },
    { path: '/hr-library', component: Library },
    { path: '/hr-tasks', component: Tasks },
    { path: '/hr-teams', component: Teams },
    { path: '/hr-program-builder', component: ProgramBuilder },
    { path: '/hr-program-builder/:programId', component: ProgramBuilder },
    { path: '/hr-progress-tracker', component: Programs },
    { path: '/hr-progress-tracker/:programId', component: ProgressTracker },
    { path: '/hr-admin', component: Admin },
  ]

  return routes.map((r, i) => (
    <Route
      key={i}
      exact
      component={r.component}
      path={`${process.env.PUBLIC_URL}${r.path}`}
    />
  ))
}

const ItRoutes = () => {
  const routes = [
    { path: '/it-dashboard', component: ITDashboard },
    { path: '/it-programs', component: Programs },
    { path: '/it-library', component: Library },
    { path: '/it-tasks', component: Tasks },
    { path: '/it-program-builder', component: ProgramBuilder },
    { path: '/it-program-builder/:programId', component: ProgramBuilder },
    { path: '/it-progress-tracker', component: Programs },
    { path: '/it-progress-tracker/:programId', component: ProgressTracker },
    { path: '/it-categories', component: CategoryList },
    { path: '/it-starter-program', component: StarterProgramList },
    { path: '/it-organization', component: OrganizationList },
  ]

  return routes.map((r, i) => (
    <Route
      key={i}
      exact
      component={r.component}
      path={`${process.env.PUBLIC_URL}${r.path}`}
    />
  ))
}

const EducatorRoutes = () => {
  const routes = [
    { path: '/educator-dashboard', component: Dashboard },
    { path: '/educator-programs', component: Programs },
    { path: '/educator-library', component: Library },
    { path: '/educator-tasks', component: Tasks },
    { path: '/educator-program-builder', component: ProgramBuilder },
    { path: '/educator-program-builder/:programId', component: ProgramBuilder },
    { path: '/educator-progress-tracker', component: Programs },
    {
      path: '/educator-progress-tracker/:programId',
      component: ProgressTracker,
    },
  ]

  return routes.map((r, i) => (
    <Route
      key={i}
      exact
      component={r.component}
      path={`${process.env.PUBLIC_URL}${r.path}`}
    />
  ))
}

const ManagerRoutes = () => {
  const routes = [
    { path: '/manager-dashboard', component: Dashboard },
    { path: '/manager-programs', component: Programs },
    { path: '/manager-library', component: Library },
    { path: '/manager-tasks', component: Tasks },
    { path: '/manager-progress-tracker', component: Programs },
    {
      path: '/manager-progress-tracker/:programId',
      component: ProgressTracker,
    },
    { path: '/manager-teams', component: Teams },
  ]

  return routes.map((r, i) => (
    <Route
      key={i}
      exact
      component={r.component}
      path={`${process.env.PUBLIC_URL}${r.path}`}
    />
  ))
}

const EmployeeRoutes = () => {
  const routes = [
    { path: '/employee-dashboard', component: EmployeeDashboard },
    { path: '/employee-programs', component: Programs },
    { path: '/employee-library', component: Library },
    {
      path: '/employee-program/:programId/:programInstanceId',
      component: EmployeeTask,
    },
    {
      path: '/members-profile/:userId/:employeeId',
      component: EmployeeProfile,
    },
    { path: '/personal-hub', component: PersonalHub },
    { path: '/personal-settings', component: PersonalSettings },
  ]

  return routes.map((r, i) => (
    <Route
      key={i}
      exact
      component={r.component}
      path={`${process.env.PUBLIC_URL}${r.path}`}
    />
  ))
}

export default function MainIndex() {
  const { userInfo, setUserInfo } = useContext(AuthContext)
  const roleName = userInfo?.employeeDetails?.role?.name
  const { t } = useTranslation()
  const history = useHistory()
  const pathname = history?.location?.pathname?.split('-')[0]?.split('/')[1]
  const [tourOpen, setTourOpen] = useState(false)

  useEffect(() => {
    if (userInfo?.isFirstLogin && isMobile) {
      setTourOpen(true)
    }
  }, [userInfo?.isFirstLogin])

  useEffect(() => {
    const isAuthorizedRole = Roles[pathname]

    if (!isAuthorizedRole || !userInfo) return
    const userRole = userInfo?.employeeDetails?.role?.name

    if (userRole !== isAuthorizedRole) {
      // toast.error(
      //   t(
      //     'notification.authorizedError',
      //     `You don't have the permission to access this resource`,
      //   ),
      // )
      history.push(`/${userRole.toLowerCase()}-dashboard`)
    }
  }, [userInfo])

  if (!roleName) return null

  return (
    <div className="main px-lg-4 px-md-4 pb-4">
      <Header />

      <div className="body d-flex pt-3">
        <GeneralRoutes roleName={roleName} />
        <HrRoutes />
        <ItRoutes />
        <EducatorRoutes />
        <ManagerRoutes />
        <EmployeeRoutes />
      </div>

      {isMobile && (
        <div
          className="position-fixed bottom-0 "
          style={{ right: 10, zIndex: 999 }}
        >
          <CaiChatProvider>
            <CaiPopup showInput={false} />
          </CaiChatProvider>
        </div>
      )}
      {tourOpen && (
        <MobileOnboarding
          onSessionEnd={() => {
            if (userInfo?.isFirstLogin) {
              localStorage.setItem('isCaiTourOpen', false)
            }
            setUserInfo((s) => ({ ...s, isFirstLogin: false }))
          }}
        />
      )}
    </div>
  )
}
