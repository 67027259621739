interface SubButton {
  id: string
  label: string
  message: string
  sub_buttons?: SubButton[]
}

export interface FeedbackChoice {
  id: string
  label: string
  message: string
  sub_buttons?: SubButton[]
}

interface FeedbackChoices {
  buttons: FeedbackChoice[]
}

export const FEEDBACK_CHOICES: FeedbackChoices = {
  buttons: [
    {
      id: 'createConstructive',
      label: 'feedbacks.choices.label.createConstructive',
      message: 'feedbacks.choices.createConstructive',
    },
    {
      id: 'prepareToSend',
      label: 'feedbacks.choices.label.prepareToSend',
      message: 'feedbacks.choices.prepareToSend',

      sub_buttons: [
        {
          id: 'askContextualQuestions',
          label: 'feedbacks.choices.label.askContextualQuestions',
          message: 'feedbacks.choices.askContextualQuestions',
        },
        {
          id: 'rewriteThis',
          label: 'feedbacks.choices.label.rewriteThis',
          message: 'feedbacks.choices.rewriteThis',
        },
        {
          id: 'bestPractice',
          label: 'feedbacks.choices.label.bestPractice',
          message: 'feedbacks.choices.bestPractice',
        },
        {
          id: 'rolePlay',
          label: 'feedbacks.choices.label.rolePlay',
          message: 'feedbacks.choices.rolePlay',
        },
      ],
    },
    {
      id: 'explainFeedFwd',
      label: 'feedbacks.choices.label.explainFeedFwd',
      message: 'feedbacks.choices.explainFeedFwd',
      sub_buttons: [
        {
          id: 'sendConstructiveFFAnonymously',
          label: 'feedbacks.choices.label.sendConstructiveFFAnonymously',
          message: 'feedbacks.choices.sendConstructiveFFAnonymously',
        },
        {
          id: 'sendConstructiveFFDirectlyOrPrepare',
          label: 'feedbacks.choices.label.sendConstructiveFFDirectlyOrPrepare',
          message: 'feedbacks.choices.sendConstructiveFFDirectlyOrPrepare',
        },
        {
          id: 'differenceBetweenFeedbackAndFeedforward',
          label:
            'feedbacks.choices.label.differenceBetweenFeedbackAndFeedforward',
          message: 'feedbacks.choices.differenceBetweenFeedbackAndFeedforward',
        },
        {
          id: 'explainFFInMyCoach',
          label: 'feedbacks.choices.label.explainFFInMyCoach',
          message: 'feedbacks.choices.explainFFInMyCoach',
        },
      ],
    },
    {
      id: 'skillImprove',
      label: 'feedbacks.choices.label.skillImprove',
      message: 'feedbacks.choices.skillImprove',
      sub_buttons: [
        {
          id: 'takeExampleAndWorkThroughIt',
          label: 'feedbacks.choices.label.takeExampleAndWorkThroughIt',
          message: 'feedbacks.choices.takeExampleAndWorkThroughIt',
        },
        {
          id: 'rolePlay',
          label: 'feedbacks.choices.label.rolePlay',
          message: 'feedbacks.choices.rolePlay',
        },
        {
          id: 'feedbacks.choices.buildProgramAndMonitor',
          label: 'feedbacks.choices.label.buildProgramAndMonitor',
          message: 'feedbacks.choices.buildProgramAndMonitor',
        },
      ],
    },
  ],
}
