import type { TourProps } from 'antd'
import { ConfigProvider, Tour } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Onboarding.scss'
import { Locale, antdLocales } from 'src/constants/i18n.constant'

interface MobileOnboardingProps {
  onSessionEnd: () => void
}

const getTourImgs = () => {
  return [
    'https://cognentai-hr-dev.s3.us-west-2.amazonaws.com/onboarding-mp3/mobile-img/Screenshot+2024-11-12+at+1.57.07%E2%80%AFAM.png',
    'https://cognentai-hr-dev.s3.us-west-2.amazonaws.com/onboarding-mp3/mobile-img/chatOptions.png',
    'https://cognentai-hr-dev.s3.us-west-2.amazonaws.com/onboarding-mp3/mobile-img/tts.png',
    'https://cognentai-hr-dev.s3.us-west-2.amazonaws.com/onboarding-mp3/mobile-img/Screenshot+2024-11-12+at+2.21.31%E2%80%AFAM.png',
    'https://cognentai-hr-dev.s3.us-west-2.amazonaws.com/onboarding-mp3/mobile-img/Screenshot+2024-11-12+at+2.27.55%E2%80%AFAM.png',
  ]
}

export const MobileOnboarding: React.FC<MobileOnboardingProps> = ({
  onSessionEnd,
}) => {
  const { t, i18n } = useTranslation()
  const ref = useRef(null)
  const [open, setOpen] = useState(true)
  const tourImgs = getTourImgs()

  useEffect(() => {
    tourImgs.forEach((url) => {
      const img = new Image()
      img.src = url
    })
  }, [])

  const steps: TourProps['steps'] = [
    {
      title: t('caiPopup.tour.step1.title'),
      description: t('caiPopup.tour.step1.description'),
      placement: 'center',
      target: ref.current,
    },
    {
      title: t('caiPopup.tourMobile.step2.title'),
      description: t('caiPopup.tourMobile.step2.description'),
      cover: (
        <img
          alt={t('caiPopup.tourMobile.step2.title')}
          loading="lazy"
          src={tourImgs[0]}
        />
      ),
      placement: 'center',
      target: ref.current,
    },
    {
      title: t('caiPopup.tourMobile.step3.title'),
      description: (
        <div>
          {t('caiPopup.tourMobile.step3.description')
            .split('\\n')
            .map((line, index) => (
              <div key={index}>{line}</div>
            ))}
        </div>
      ),
      cover: (
        <img
          alt={t('caiPopup.tourMobile.step3.title')}
          loading="lazy"
          src={tourImgs[1]}
        />
      ),

      placement: 'center',
      target: ref.current,
    },
    {
      title: t('caiPopup.tourMobile.step4.title'),
      cover: (
        <img
          alt={t('caiPopup.tourMobile.step4.title')}
          loading="lazy"
          src={tourImgs[2]}
        />
      ),
      placement: 'center',
      target: ref.current,
    },
    {
      title: t('caiPopup.tourMobile.step5.title'),
      cover: (
        <img
          alt={t('caiPopup.tourMobile.step5.title')}
          loading="lazy"
          src={tourImgs[3]}
        />
      ),
      placement: 'center',
      target: ref.current,
    },
    {
      title: t('caiPopup.tourMobile.step6.title'),
      description: t('caiPopup.tourMobile.step6.description'),
      cover: (
        <img
          alt={t('caiPopup.tourMobile.step6.title')}
          loading="lazy"
          src={tourImgs[4]}
        />
      ),
      placement: 'center',
      target: ref.current,
    },
  ]

  const handleClose = () => {
    setOpen(false)
    onSessionEnd()
  }

  return (
    <>
      {open && (
        <div
          ref={ref}
          style={{
            margin: 0,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
      <ConfigProvider locale={antdLocales[i18n.language as Locale]}>
        <Tour
          arrow={false}
          mask={false}
          open={open}
          rootClassName="mobile-ant-tour"
          steps={steps}
          gap={{
            offset: 0,
            radius: 0,
          }}
          onClose={handleClose}
        />
      </ConfigProvider>
    </>
  )
}
