import get from 'lodash/get'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import {
  Organization,
  WorkingRole,
  useUpdateUserRoleMutation,
} from 'src/gql/graphql'
import { AuthContext } from '../Auth/AuthProvider'

export default function OrganizationSwitcher() {
  const { userInfo, setUserInfo } = useContext(AuthContext)
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | null | undefined
  >(userInfo?.organization)

  const [currentOrgId, setCurrentOrgId] = useState<string | null>(
    localStorage.getItem('organizationId'),
  )

  const [switchProfile, { loading: switchProfileLoading }] =
    useUpdateUserRoleMutation()

  const organizationList = useMemo(() => {
    return userInfo?.userOrganizations?.map((userOrg) => userOrg.organization)
  }, [userInfo?.organization])

  useEffect(() => {
    const organisation = get(userInfo, 'organization')
    if (organisation) {
      setCurrentOrgId(organisation.id)
    }
  }, [userInfo])

  useEffect(() => {
    if (!organizationList?.length) return

    const localStorageOrg = organizationList.find(
      (org) => org.id === currentOrgId,
    )
    const defaultOrg = userInfo?.organization

    if (!localStorageOrg && !defaultOrg) {
      console.error(
        'Failed to load organizations: No active or stored organization found.',
      )
    }

    if (!localStorageOrg && defaultOrg)
      localStorage.setItem('organizationId', defaultOrg.id)

    setCurrentOrganization(localStorageOrg ?? defaultOrg)
  }, [organizationList, currentOrgId])

  const handleOrganizationChange = (organizationId: string) => {
    localStorage.setItem('organizationId', organizationId)
    setCurrentOrgId(organizationId)

    const updatedOrganization = organizationList?.find(
      (org) => org.id === organizationId,
    )

    let newUserRoleId = ''

    setUserInfo((prev) => {
      if (!prev) return prev

      const updatedRoles = prev.permissions?.find(
        (permission) => permission.organizationId === organizationId,
      )?.workingRoles as WorkingRole[]

      if (
        updatedRoles.every((role) => role.id !== prev.employeeDetails?.role?.id)
      ) {
        newUserRoleId = updatedRoles[0].id
      }

      return {
        ...prev,
        organization: updatedOrganization,
        roles: updatedRoles,
      }
    })

    if (newUserRoleId && userInfo?.employeeDetails?.employeeId) {
      switchProfile({
        variables: {
          employeeId: userInfo.employeeDetails.employeeId,
          roleId: newUserRoleId,
        },
        onCompleted: () => {
          window.location.reload()
        },
      })
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="nav-link d-flex align-items-center"
        id="dropdown-language"
        style={{ color: 'var(--color-on-primary)' }}
        variant="link"
      >
        <div
          className="me-2 border rounded-circle text-center"
          style={{ width: 20, height: 20, fontSize: 13 }}
        >
          {currentOrganization?.name[0]}
        </div>

        <span className="fs-6 d-inline-block">{currentOrganization?.name}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="p-1">
        {organizationList?.map((organization) => {
          const { id, name } = organization

          return (
            <Dropdown.Item
              key={id}
              className="rounded"
              style={{
                ...(id === currentOrgId && {
                  background: 'var(--primary-color)',
                  color: 'var(--color-on-primary)',
                }),
              }}
              onClick={() => id && handleOrganizationChange(id)}
            >
              {name}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
