import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Tooltip from '../UI/Tooltip'
import { toast } from 'react-toastify'

function ReportButton({ onReport }: { onReport: (text: string) => void }) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = React.useState(false)
  const [reportText, setReportText] = React.useState('')

  const handleReportButtonClick = () => setShowModal(true)

  const handleReportTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setReportText(e.target.value)

  const handleClose = () => {
    setShowModal(false)
    setReportText('')
  }

  const handleSubmitReport = () => {
    onReport(reportText)
    toast.success(
      t('notification.sendReportSuccess', 'Report is sent successfully'),
    )
    handleClose()
  }

  return (
    <React.Fragment>
      <Tooltip
        message={t(
          'agent.reportButton.tooltip',
          'Report an issue to the MyCoach support team',
        )}
      >
        <button
          className="btn btn-warning position-absolute px-2 py-1 rounded"
          style={{ bottom: '100px', right: '35px' }}
          onClick={handleReportButtonClick}
        >
          {t('common.button.report', 'Report')}
        </button>
      </Tooltip>

      {showModal && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              {t('agent.reportButton.modalTitle', 'Report Issue')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              {t(
                'agent.reportButton.modalDescription',
                "This will send an email including only this chat's history to the MyCoach support team for review.",
              )}
            </p>

            <p>
              {t(
                'agent.reportButton.modalBodyDescription',
                'Describe the issue you encountered',
              )}
              :
            </p>

            <textarea
              className="form-control"
              rows={5}
              value={reportText}
              onChange={handleReportTextChange}
            />
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              {t('common.button.cancel', 'Cancel')}
            </button>

            <button
              className="btn btn-primary"
              disabled={!reportText.trim()}
              onClick={handleSubmitReport}
            >
              {t('common.button.submit', 'Submit')}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default ReportButton
